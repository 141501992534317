import { useEffect } from 'react';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { isoLocalDateFormatter } from '@property-folders/common/util/formatting';
import { ContractSettlementType, MaterialisedPropertyData } from '@property-folders/contract';
import { usePrevious } from 'react-use';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { generateParentPath } from '@property-folders/common/util/pathHandling';
import {
  contractSettlementDefaultEnables,
  defaultConditionalDays,
  defaultSettlementDays
} from '@property-folders/common/data-and-text/constants';

export const OfferSettlement = (props: TransactionConsumerProps ): JSX.Element => {
  const { value: settlement, fullPath } = useTransactionField<ContractSettlementType>(props);
  const { value: contractSpecialValue } = useLightweightTransaction<MaterialisedPropertyData['contractSpecial']>({ parentPath: `${generateParentPath(fullPath)}.contractSpecial` });
  const { updateDraft: updateSettlementDraft } = useYdocBinder<ContractSettlementType>({ path: fullPath });

  const previous = usePrevious(settlement);

  let minSettlement = new Date();
  const approveDate = new Date(contractSpecialValue?.financeDeadline);
  const settleDate = new Date(contractSpecialValue?.purchaserSaleSettlementDeadline);
  if (contractSpecialValue?.financeRequired && contractSpecialValue.purchaserSaleRequired) {
    if (!isNaN(approveDate.getTime()) && !isNaN(settleDate.getTime())) {
      minSettlement = new Date(Math.max(approveDate.getTime(), settleDate.getTime()));
    }

  } else if (contractSpecialValue?.financeRequired) {
    if (!isNaN(approveDate.getTime())) {
      minSettlement = approveDate;
    }
  } else if (contractSpecialValue?.purchaserSaleRequired) {
    if (!isNaN(settleDate.getTime())) {
      minSettlement = settleDate;
    }
  }

  const exclusiveSelector = (newTrue: 'onContract' | 'onDate' | 'onCondition' | 'other') => {
    if (previous?.onDate && newTrue === 'onDate') return;
    if (previous?.onCondition && newTrue === 'onCondition') return;
    if (previous?.onContract && newTrue === 'onContract') return;
    if (previous?.other && newTrue === 'other') return;

    updateSettlementDraft?.(draft => {

      delete draft.onContract;
      delete draft.onDate;
      delete draft.onCondition;
      delete draft.other;
      switch (newTrue) {
        case 'onCondition': {
          draft.onCondition = true;
          return;
        }
        case 'onDate': {
          draft.onDate = true;
          return;
        }
        case 'onContract': {
          draft.onContract = true;
          return;
        }
        case 'other': {
          draft.other = true;
          return;
        }
      }
    });
  };

  useEffect(() => {
    if (settlement?.onContract && previous?.onContract !== settlement.onContract) {
      exclusiveSelector('onContract');
    } else if (settlement?.onDate && previous?.onDate !== settlement.onDate) {
      exclusiveSelector('onDate');
    } else if (settlement?.onCondition && previous?.onCondition !== settlement.onCondition) {
      exclusiveSelector('onCondition');
    } else if (settlement?.other && previous?.other !== settlement.other) {
      exclusiveSelector('other');
    }
  }, [previous, settlement]);

  const optionVisibility = settlement?.enabledModes && Object.values(settlement?.enabledModes??{}).some(a=>a) ? settlement.enabledModes : contractSettlementDefaultEnables;

  const otherConditonAsSet = settlement?.otherText;
  return <div className="w-100">
    <div className='lead'>What is your preferred date for settlement?</div>
    {optionVisibility.date && <span className="mt-3">
      <WrField.CheckRadio
        radioType="radio"
        className="form-check-with-inner-input"
        options={{
          'true': <>
            I would prefer settlement to occur on
            <WrField.Control
              onChange={() => {
                exclusiveSelector('onDate');
              }}
              inlineSpan={true}
              type="date"
              dateFromOther={isoLocalDateFormatter(minSettlement)}
              name="date"
              style={{ width: '160px' }}
              parentPath={fullPath}
              myPath="date"
              useCanonical={true}
              key={'settlementSetDate'}

            />
          </>
        }}
        valueType="boolean"
        inline={true}
        parentPath={fullPath}
        name="onDate"
        myPath="onDate"
      />

    </span>}

    {optionVisibility.contract && <div className="mt-3">
      <WrField.CheckRadio
        radioType="radio"
        className="form-check-with-inner-input"
        options={{
          'true': <>
            I would prefer settlement to be
            <WrField.Control
              onChange={() => {
                exclusiveSelector('onContract');
              }}
              inlineSpan={true}
              placeholder="##"
              useCanonical={true}
              textEnd={true}
              style={{ width: '70px' }}
              parentPath={fullPath}
              name="onContractDays"
              myPath="onContractDays"
              key={'settlementSetContractDays'}
              setDefaultValue={`${defaultSettlementDays}`}
              defaultValueLabel={defaultSettlementDays ? `${defaultSettlementDays} days` : undefined}
            />
            days after the signing of the contract
          </>
        }}
        valueType="boolean"
        inline={true}
        parentPath={fullPath}
        name="onContract"
        myPath="onContract"
      />
    </div>}

    {optionVisibility.condition && <div className="mt-3">
      <WrField.CheckRadio
        radioType="radio"
        className="form-check-with-inner-input"
        options={{
          'true': <>
            I would prefer settlement to be
            <WrField.Control
              onChange={() => {
                exclusiveSelector('onCondition');
              }}
              inlineSpan={true}
              placeholder="##"
              useCanonical={true}
              textEnd={true}
              style={{ width: '70px' }}
              parentPath={fullPath}
              name="onConditionDays"
              myPath="onConditionDays"
              key={'settlementSetConditionDays'}
              setDefaultValue={`${defaultConditionalDays}`}
              defaultValueLabel={defaultConditionalDays ? `${defaultConditionalDays} days` : undefined}
            />
            days after the Special conditions and/or Additional conditions are satisfied or waived
          </>
        }}
        valueType="boolean"
        inline={true}
        parentPath={fullPath}
        name="onCondition"
        myPath="onCondition"
      />
    </div>}

    {optionVisibility?.other && otherConditonAsSet && <div className="mt-3">
      <WrField.CheckRadio
        radioType="radio"
        className="form-check-with-inner-input"

        options={{
          'true': <div>
            Other: {otherConditonAsSet}
          </div>
        }}
        valueType="boolean"
        inline={true}
        parentPath={fullPath}
        name="otherSettlement"
        myPath="other"
      />
    </div>}
  </div>;
};
