import { ContentCard } from '~/components/content-card';
import { Card } from 'react-bootstrap';
import { ContractPrice } from '@property-folders/components/dragged-components/form/SaleContract/contractPrice';
import {
  ContractSpecialConditionsFinance
} from '@property-folders/components/dragged-components/form/SaleContract/ContractSpecialConditionsFinance';
import {
  ContractSpecialConditionsPurchaserSale
} from '@property-folders/components/dragged-components/form/SaleContract/ContractSpecialConditionsPurchaserSale';
import { ReactNode } from 'react';

import { FormUserInteractionContext } from '@property-folders/components/context/FormUserInteractionContext';
import { OfferAdditionalConditionsFreeText } from '@property-folders/components/dragged-components/form/SaleContract/OfferAdditionalConditionsFreeText';
import { useForm } from '@property-folders/components/hooks/useForm';
import { OfferSettlement } from '@property-folders/components/display/form/OfferSettlement';
import { contractSettlementDefaultEnables } from '@property-folders/common/data-and-text/constants';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { ContractSettlementType } from '@property-folders/contract';

const forceShowContext = { userShouldSeeAllValidation: true };

export function OfferPrepareDetails({ contractMode, footer, hideFinance, hidePurchaserSale }: { contractMode?: boolean, footer: ReactNode | undefined, hideFinance: boolean, hidePurchaserSale: boolean }) {
  useForm(); // Run validation on this page. Cannot be hoisted into authenticated offer as it requires the FormContext set up for each step

  const { value: settlement } = useLightweightTransaction<ContractSettlementType>({ myPath: 'contractSettlement' });
  const settlementOptionVisibility = settlement?.enabledModes && Object.values(settlement?.enabledModes??{}).some(a=>a) ? settlement.enabledModes : contractSettlementDefaultEnables;
  // If only the other settlement condition is given, we don't allow the user to pick it, as they can't modify the
  // condition anyway
  const hideSettlement = Object.entries(settlementOptionVisibility).filter(([key]) => key !== 'other').every(([_k, v]) => !v);

  return <FormUserInteractionContext.Provider value={forceShowContext}>
    <ContentCard>
      <Card.Header>
        <h4>Offer</h4>
      </Card.Header>
      <Card.Body>
        <ContractPrice myPath='contractPrice' purchaserMode={true} />
      </Card.Body>
    </ContentCard>

    {!(hideFinance && hidePurchaserSale)  && <ContentCard>
      <Card.Header>
        <h4>Special conditions</h4>
      </Card.Header>
      <Card.Body className='space-children'>
        {!hideFinance && <div>
          <div className='fs-4'>Purchaser finance</div>
          <ContractSpecialConditionsFinance radioEnable={true} purchaserMode={true} />
        </div>}
        {!hidePurchaserSale && <div>
          <div className='fs-4'>Purchaser sale</div>
          <ContractSpecialConditionsPurchaserSale radioEnable={true} purchaserMode={true} letterOfOffer={!contractMode} />
        </div>}
      </Card.Body>
    </ContentCard>}

    <ContentCard>
      <Card.Header>
        <h4>Additional conditions</h4>
      </Card.Header>
      <Card.Body>
        <div className='lead'>If you require any additional conditions be included in your offer, please set those out here:</div>
        <OfferAdditionalConditionsFreeText />
      </Card.Body>
      {hideSettlement && <Card.Footer className="d-flex flex-row justify-content-end gap-2">
        {footer}
      </Card.Footer>}
    </ContentCard>

    {!hideSettlement && <ContentCard>
      <Card.Header>
        <h4>Settlement</h4>
      </Card.Header>
      <Card.Body>
        <OfferSettlement myPath="contractSettlement"/>
      </Card.Body>
      <Card.Footer className="d-flex flex-row justify-content-end gap-2">
        {footer}
      </Card.Footer>
    </ContentCard>}

  </FormUserInteractionContext.Provider>;
}
